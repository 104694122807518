
/************************************************************************************************************/
/**********************************    GH-AUTOCOMPLETE CONTROL    *******************************************/
/************************************************************************************************************/

/*  This attribute with gh-input fill drop-down with options, started from ghinput from atribute "autocompleate"*/
/*  I could have next argument:*/

/*    __APP LIST_____________ITEMS LIST________________________FIELDS LIST____________________________USERS LIST________
/*   | [0] - appsList   |    [0] - itemsList    | [0] - fieldsList                              |   [0] - usersList    |*/
/*	 |	                |    [1] - appId   	    | [1] - appId                                   |   [1] - keyword      |*/
/*	 |	                |    [2] - fieldId      | [2] - namespace | '' (if defined then         |                      |*/
/*   |                  |                       |   returns namespace instead field_id)         |                      |*/
/*   |                  |                       | [3] - data_type filter | ''                   |                      |*/
/*   |                  |                       | [4] - field_id to exclude (to protect         |                      |*/
/*   |                  |                       |   recursion in calc for example)              |                      |*/
/*   |__________________|_______________________|_______________________________________________|______________________|*/
/*  EXAMPLE*/
/*    autocomplete="itemsList 21 110"*/
/****************************************************************************************************/




angular.module('autoCompleteMod', [])

.service('AutoComplete', [ '$q', '$location', 'appDataProcesingService', 'authService', 'PipeService', '$rootScope', '$timeout', '$routeParams', function($q, $location, appDataProcesingService, authService, PipeService, $rootScope, $timeout, $routeParams) {


  /*------------------------------ GETTING -ITEMS- LIST ---------------------------------*/
    this.getItemsList = function(args) {
      var appIDs = args[1].split(','), fieldsIDs = args[2].split(',');

      if (args.length !== 3) {
        return $q.when('not_enough_args');
      }

      let appsFromStore = [];
      async function getApp(curr) {
        const app = await appDataProcesingService.getApp(curr);
        if(app){
          appsFromStore.push(app);
        }
      }
      return Promise.all(appIDs.map((id) => getApp(id)))
        .then(() => {
          return Promise.all(appsFromStore.reduce((prev, curr, index) => {
            return [...prev, ...curr.items_list.map((item) => {
              return getFieldValue({app_id: curr.app_id, item_id: item.item_id, field_id: fieldsIDs[index]});
            })];
          }, [])
          );
        })
        .then((res) => res.filter((item) => item.name));

      function getFieldValue(args) {
        return new Promise(async (resolve) => {
          let interpretatedValue = await gudhub.getInterpretationById(args.app_id, args.item_id, args.field_id, 'value');
          resolve({ name: interpretatedValue, value: args.app_id + '.' + args.item_id });
        });
      }
    };
/*------------------------------ GETTING GROUPE ITEMS LIST -----------------------------*/
  /* ARGS = 1. APP ID
          2. FILED ID
  */
  this.groupeItemList = function(args){
    let deferred = $q.defer();
    let appIDs = args[1], fieldsIDs = args[2];
    let returnArray = [];

    let address = {
      app_id:  appIDs
    };

    PipeService.on('gh_items_get', address, function getAppItemsPipe(event, items) {
      PipeService.destroy('gh_items_get', address, getAppItemsPipe);
      const groupedItems = gudhub.util.group(fieldsIDs, items)
      groupedItems.forEach((item, index) => {
        item.fields.forEach(field => {
          if(field.field_id == fieldsIDs){
            returnArray.push({name: field.field_value, value: field.field_value})
          }
        })
        if(index == groupedItems.length-1){
          deferred.resolve(returnArray.filter((item) => {
            return item.name
          }));
        }
    })

    }).emit('gh_items_get', {}, address);

    // deferred.resolve({name: val, value: args.app_id + '.' + args.item_id});

    return deferred.promise
  }
/*------------------------------ GETTING FILTER ITEMS LIST -----------------------------*/
/* ARGS = 1. APP ID
          2. FILED ID
          3. SOURCE ITEM {APP ID CURRENT APP, ITEM ID CURRENT APP}
          4. FILTER [{ FILTER }]
*/
    this.filterItemList = function(args){
      var appIDs = args[1].split(','), fieldsIDs = args[2].split(','), source_item = JSON.parse(args[3].replace(/'/g, "\"")), filterList = JSON.parse(args[4].replace(/'/g, "\""));
      var deferred = $q.defer();
      const itemId = source_item.ghItemId || $routeParams.param_3 || '';
      let appsFromStore = [];
      async function getApp(curr) {
        const app = await appDataProcesingService.getApp(curr);
        if(app){
          appsFromStore.push(app);
        }
      }

      $q.all(appIDs.map((id) => getApp(id)))
          .then(() => {
            var promises = [];

            appsFromStore.forEach(async function(curr, index){
              var filterObj = filterList.find(filter => {
                return filter.app_id == curr.app_id
              })
              const modified_filters_list = await gudhub.prefilter(angular.copy(filterObj ? filterObj.filters_list : curr.filters_list), { element_app_id: filterObj ? filterObj.app_id : curr.app_id, app_id: source_item.ghAppId, item_id: itemId })
              const itemsList = gudhub.filter(curr.items_list, modified_filters_list)
                  itemsList.map((item) => {
                    promises.push(getFieldValue({app_id: curr.app_id, item_id: item.item_id, field_id: fieldsIDs[index]}));
                  })
                  if(index == appsFromStore.length-1){
                    returnPromise(promises)
                  }
          })
        })

        function returnPromise(promises){
          $q.all(promises).then(function(res) {
            deferred.resolve(res.filter((item) => {
              return item.name
            }));
          })
        }

      return deferred.promise

        function getFieldValue(args) {
          return new Promise(async (resolve) => {
            let val = await gudhub.getInterpretationById(args.app_id, args.item_id, args.field_id, 'value');

            resolve({name: val, value: args.app_id + '.' + args.item_id});
          });
        }
    }


/*------------------------------ GETTING -FIELDS- LIST ---------------------------------*/
		this.getFieldsList = function(args) {
			var self = this, appID = args[1], returnNamespace = args[2], filterByType = args[3], fieldIdToExclude = args[4];

      var deferred = $q.defer(), scope = $rootScope.$new(), options = [];
      if (Number(appID)) {
        PipeService.on('gh_models_get', {app_id: appID}, function modelsPipe(event, field_models) {
          angular.forEach(field_models, function(field) {
            if (!filterByType || filterByType && filterByType == field.data_type) {
              if (!fieldIdToExclude || fieldIdToExclude && (field.field_id != fieldIdToExclude)) {
                var optionField = {
                  value: returnNamespace == 'namespace' ? field.name_space : field.field_id,
                  name: field.field_name,
                  type: field.data_type
                };
                options.push(optionField);
              }
            }
          });
          PipeService.destroy('gh_models_get', {app_id: appID}, modelsPipe);

          // hard fix
          $timeout(function() {
            scope.$destroy();
          });

          deferred.resolve(options);
        }, scope).emit('gh_models_get', {}, {app_id: appID});
      } else {
        deferred.resolve('not_enough_args');
      }

      return deferred.promise;
		};





/*------------------------------ GETTING -APPLICATIONS- LIST ---------------------------------*/
		this.getAppsList = function() {
			var self = this, options = [];
			return appDataProcesingService.getAppsList(this.appUrl).then(function(result) {

        return result.map(function (app) {
          return {
            name: app.app_name,
            value: app.app_id,
            app: app
          };
        });
			},function(err) {
				return $q.resolve([]);
			});
		};
		this.getViewList = function(args) {
      const app_id = args[1];
      const deferred = $q.defer();
       PipeService.on('gh_app_get', { app_id }, function getApp(event, app){
        let options = [];
        if(app.app_id){
          options = app.views_list.map(view => {
            return {
              name: view.name,
              value: view.view_id
            };
          });
        }
        deferred.resolve(options);
        PipeService.destroy('gh_app_get',{ app_id }, getApp)
      }).emit('gh_app_get', {}, { app_id })
      return deferred.promise;
		};

    this.getUsersList = function(args) {
      var key = args[1];
      var MIN_LENGTH_FOR_REQUEST = 3;

      if (!key || key.length < MIN_LENGTH_FOR_REQUEST) {
        return $q.when([]);
      }

      return authService.getUsersList(key).then(function(response) {
        angular.forEach(response, function(user) {
          user.value = user.user_id;
          user.name = user.fullname;
        });
        return response;
      });
    };
}])



.factory('getItemsInTitlesFormat', [function () {
  'use strict';
  function isMatched(array, search) {
    if (!search) {
      return true;
    }

    return array.some(function (_field) {
      if (!_field) {
        return false;
      }
      return String(_field).toLowerCase().indexOf(search.toLowerCase()) != -1;
    });
  }

  function convertTitlesId(title = "") {
    return title.split(",").map(Number);
  }

  async function getTitlesId(fields = [], titles = [], item_id, app_id) {
    return Promise.all(fields
      .filter((field) => titles.includes(field.field_id))
      .sort(
        (prevField, nextField) =>
          titles.indexOf(prevField.field_id) -
          titles.indexOf(nextField.field_id)
      )
      .map(async (field) => {
        const value = await gudhub.getInterpretationById(
          app_id,
          item_id,
          field.field_id,
          "value",
          field.field_value
          
        );
        return value;    
      })
    );
  }

  return async function (app, items, search) {
    return Promise.all(items.map(async function (_item) {
      return {
        thumbnail: (_item.fields
            .find(_field => _field.field_id == app.thumbnail_field_id) || {}).field_value,
        titles: await getTitlesId(_item.fields, convertTitlesId(app.title_field_ids), _item.item_id, app.app_id),
        subtitles: await getTitlesId(_item.fields, convertTitlesId(app.subtitle_field_ids), _item.item_id, app.app_id),
        itemSource: _item,
        appIdFrom: app.app_id,
        sourceApp: app
      };
    }))
      .then(filteredItems => filteredItems.filter(_item => {
        return isMatched(_item.titles, search) || isMatched(_item.subtitles, search);
      }));
  };
}]);
